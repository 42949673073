<template>
  <div id="app">
    <div id="viewer-container" style="width: 100vw; height: 100vh;"></div>
  </div>
</template>

<script>
import { IfcViewerAPI } from 'web-ifc-viewer';

export default {
  name: 'App',
  mounted() {
    const url = new URLSearchParams(window.location.search).get('param');

    const container = document.getElementById('viewer-container');
    const viewer = new IfcViewerAPI({ container });
    viewer.addAxes();
    viewer.addGrid();
    viewer.setWasmPath("../files/");
    viewer.loadIfcUrl(url, true).then();
  }
}
</script>

<style>
body {
  margin: 0;
  height: 100%;
}
</style>
